export const configType = {
    expToken: 5,
    contentTypePDF: 'application/pdf',
    contentTypeJSON: 'application/json',
    contentTypeZIP: 'application/zip',
    contentTypeXZIP: 'application/x-zip-compressed',
    fileTypeXML: 'text/xml',
    fileTypeTXT: 'text/plain',
    recursoAll: 'dashboard',
    errorInterno: 'Error interno.',
    stdComprobante: 'Comprobante',
    stdDocumento: 'Documento',
    catTipoCpe: '01',
    catMoneda: '02',
    monedaISOPEN: 'PEN',
    searchAllCode: 'all code',
    fileExtTXT: '.txt',
    fileExtXML: '.xml',
    fileExtJSON: '.json',
    fileExtZIP: '.zip',
    fileExtAny: '.*',
    msgWarning: 'warning',
    msgError: 'error',
    msgSuccess: 'success',
    version: 'v1.1.3',
    attachMaxSize: 1024 * 1024 * 7,
    responsiveLg: 1590,
    ctoSuscriptor: process.env.REACT_APP_API_Suscriptor_Calisto,
}