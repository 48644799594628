import { RestOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { Alert, Button, Card, DatePicker, Form, Input, InputNumber, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { bllConsultarUrl, bllRecuperarUrl, catGetCodUrl } from '../../api/apiUrl'
import { useAuth } from '../../hooks/useAuth'
import { useAxiosDne } from '../../hooks/useAxiosDne'
import { configType } from '../../types/configType'
import { msgType } from '../../types/msgType'
import { downloadFile, viewerFile } from '../../utils/fileUtil'
import { assetsImg } from '../../utils/imgUtil'
import { AppFooter } from '../../components/AppFooter'

import './MiCpe.css'

var jszip = require('jszip')

const imgPath_app = assetsImg('./app600.svg')
const siteUrl_grc = process.env.REACT_APP_reCAPTCHA_Site_Url
const siteKey_grc = process.env.REACT_APP_reCAPTCHA_Site_Key

export const MiCpe = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { axiosDnePost } = useAxiosDne()
    const [formCpe] = Form.useForm()
    const [loadPage, setLoadPage] = useState(true)
    const [loadCaptcha, setLoadCaptcha] = useState(true)
    const [loadTipoCpe, setLoadTipoCpe] = useState(true)
    const [loadBuscarCpe, setLoadBuscarCpe] = useState(false)
    const [loadDescargarCpe, setLoadDescargarCpe] = useState(false)
    const [tiposCpe, setTiposCpe] = useState([])
    const [comprobante, setComprobante] = useState(null)
    const [adjuntos, setAdjuntos] = useState([])
    const [foAdjunto, setFoAdjunto] = useState(null)
    const [messageApp, setMessageApp] = useState(null)
    const [messageType, setMessageType] = useState(null)

    useEffect(() => {
        setLoadPage(loadCaptcha || loadTipoCpe || loadBuscarCpe || loadDescargarCpe)
    }, [loadCaptcha, loadTipoCpe, loadBuscarCpe, loadDescargarCpe])

    useEffect(() => {
        if (comprobante) {
            const aadj = comprobante.adjuntos?.filter(p => p.fileType)?.map((p, index) => ({ ...p, index, label: (p.fileType ? `${p.fileType.toUpperCase()} : ${p.fileName}` : p.fileName) })) || []
            const sadj = aadj?.find(p => true) || null
            setAdjuntos(aadj)
            setFoAdjunto(sadj?.index)
        }
        else {
            setAdjuntos([])
            setFoAdjunto(null)
        }
    }, [comprobante])

    // useEffect(() => {
    //     const loadScriptByURL = (id, url, callback) => {
    //         const myScript = document.getElementById(id)

    //         if (!myScript) {
    //             var script = document.createElement("script")
    //             script.type = "text/javascript"
    //             script.src = url
    //             script.id = id
    //             script.onload = () => {
    //                 if (callback) callback()
    //                 setLoadCaptcha(false)
    //             }
    //             document.body.appendChild(script);
    //         }
    //         if (myScript) {
    //             if (callback) callback()
    //             const divElement = document.getElementsByClassName("grecaptcha-badge")
    //             if (divElement && divElement.length > 0)
    //                 divElement[0].parentElement.style.display = 'block'
    //         }
    //     }

    //     loadScriptByURL("recaptcha-key", `${siteUrl_grc}=${siteKey_grc}`, () => { })
    // }, [])

    useEffect(() => {
        const id = "recaptcha-key"
        const url = `${siteUrl_grc}=${siteKey_grc}`

        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = () => {
            setLoadCaptcha(false)
        }
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script)
        }
    }, [])


    useEffect(() => {
        let isMounted = true

        const catTipoCpe = async () => {
            getToken('catGetCod', async token => {
                const url = catGetCodUrl(configType.catTipoCpe, false)
                const [data, err] = await axiosDnePost(url, { app: auth.app, tokenGrc: token })
                if (data)
                    isMounted && setTiposCpe(data.detalle.map(p => ({ ...p, label: (p.string1 || p.nombre) })))
                if (err)
                    isMounted && showAlert(msgType.apiError, configType.msgError, 10000)
                setLoadTipoCpe(false)
            }, () => setLoadTipoCpe(false))
        }

        if (!loadCaptcha)
            catTipoCpe()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadCaptcha])

    const getToken = (action, ready, noready) => {
        if (window.grecaptcha?.ready)
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(siteKey_grc, { action: action }).then(async (token) => await ready(token))
            })
        else {
            noready()
            console.log('recaptcha no ready')
        }
    }

    const showAlert = (msg, type, time) => {
        setMessageType(type)
        setMessageApp(msg)
        setTimeout(() => { setMessageApp(null); setMessageType(null) }, time)
    }

    const onValuesChangeCpe = (changedValues, allValues) => {
        const emsrNumDoc = allValues.emsrNumDoc ?? null
        const serie = allValues.serie ?? null

        if (changedValues.hasOwnProperty('emsrNumDoc')) {
            const f_emsrNumDoc = emsrNumDoc?.toUpperCase()?.trim() || null
            formCpe.setFieldsValue({
                emsrNumDoc: f_emsrNumDoc,
            })
        }
        if (changedValues.hasOwnProperty('serie')) {
            const f_serie = serie?.toUpperCase()?.trim() || null
            formCpe.setFieldsValue({
                serie: f_serie,
            })
        }
    }

    const onFinish = (values) => {
        setLoadBuscarCpe(true)
        getToken('bllConsultar', async token => {
            const fechaEmison = values["fechaEmision"].format().split('T')[0] + 'T00:00:00'
            const url = bllConsultarUrl(null, false)
            const [data, err] = await axiosDnePost(url, {
                app: auth.app,
                tokenGrc: token,
                emsrNumDoc: values["emsrNumDoc"],
                tipoComprobanteID: values["tipoCpe"],
                serie: values["serie"],
                numero: values["numero"],
                fechaEmision: fechaEmison,
                total: values["total"],
            })
            if (data) {
                const { data: cpe, success } = data
                if (success) {
                    setComprobante(cpe)
                    showAlert(`${msgType.comprobanteSiExiste} ${cpe.emsrNumDoc}-${cpe.tipoComprobanteID}-${cpe.serie}-${cpe.numero} ${cpe.estado}`, configType.msgSuccess, 15000)
                }
                else {
                    setComprobante(null)
                    showAlert(msgType.comprobanteNoExiste, configType.msgWarning, 15000)
                }
            }
            if (err)
                showAlert(msgType.apiError, configType.msgError, 10000)
            setLoadBuscarCpe(false)
        }, () => setLoadBuscarCpe(false))
    }

    const onFinishFailed = (errorInfo) => {
        setComprobante(null)
        showAlert(msgType.registroNoReq, configType.msgWarning, 10000)
    }

    const onReset = () => {
        formCpe.resetFields()
        setComprobante(null)
    }

    const handleDownFile = (download) => {
        const record = adjuntos.find(p => p.index === foAdjunto)
        setLoadDescargarCpe(true)
        getToken('bllRecuperar', async token => {
            const url = bllRecuperarUrl(comprobante.cpe_id, record.fileType, null, false)
            const [data, err] = await axiosDnePost(url, { app: auth.app, tokenGrc: token })
            if (data) {
                try {
                    const { document } = data
                    const zip = new jszip()
                    const zresult = await zip.loadAsync(document, { base64: true })
                    const unzip = await zresult.file(record.fileName).async('base64')
                    if (!download && record.contentType === configType.contentTypePDF)
                        viewerFile(unzip, record.contentType, record.fileName)
                    else
                        downloadFile(unzip, record.contentType, record.fileName)
                }
                catch (error) {
                    showAlert(msgType.apiError, configType.msgError, 10000)
                    console.log(error.message)
                }
            }
            if (err)
                showAlert(msgType.apiError, configType.msgError, 10000)
            setLoadDescargarCpe(false)
        }, () => setLoadDescargarCpe(false))
    }

    return (
        <div className='bg-gray-100 h-dvh flex justify-center'>
            <div className='bg-gray-100 flex flex-col space-y-1 items-center pt-1 md:pt-0 md:justify-center w-[22.5rem] md:w-[32.5rem]'>
                <div className='flex space-x-1 w-full pl-4 pb-1 items-center cursor-pointer' onClick={() => navigate('/')}>
                    <img
                        className="h-12 w-auto"
                        src={imgPath_app}
                        alt="Dione"
                    />
                    <span>Dione</span>
                </div>
                <div className='bg-white text-xs w-full p-2 rounded-lg'>
                    <span>Ingresa los datos de tu comprobante para poder descargar los archivos asociados.</span>
                </div>
                <Card title={"Busca tu comprobante"} bordered={false} className='w-full'>
                    <Form
                        name="basic"
                        labelCol={{ span: 9, }}
                        wrapperCol={{ span: 12, }}
                        initialValues={{
                            tipoCpe: null,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onValuesChangeCpe}
                        autoComplete="off"
                        form={formCpe}
                        className='micpe-form'
                    >
                        <Form.Item
                            label="RUC del emisor"
                            name="emsrNumDoc"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese el número de RUC',
                                },
                            ]}
                        >
                            <Input maxLength={15} placeholder='Número de RUC' />
                        </Form.Item>

                        <Form.Item
                            name="tipoCpe"
                            label="Tipo de comprobante"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese el tipo de comprobante',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Selecciona un tipo"
                                allowClear
                            >
                                {
                                    tiposCpe.map(({ codigo, label }) => (
                                        <Select.Option key={codigo} value={codigo}>{label}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Serie"
                            name="serie"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese la serie',
                                },
                            ]}
                        >
                            <Input maxLength={4} placeholder='Serie del comprobante' />
                        </Form.Item>

                        <Form.Item
                            label="Número"
                            name="numero"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese el número',
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder='Número de comprobante'
                                className='w-full'
                                maxLength={20} min={1} precision={0}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Fecha de emisión"
                            name="fechaEmision"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese fecha de emisión',
                                },
                            ]}
                        >
                            <DatePicker placeholder='Seleccione fecha' className='w-full' />
                        </Form.Item>

                        <Form.Item
                            label="Importe total"
                            name="total"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese el monto',
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder='Total del comprobante'
                                className='w-full'
                                maxLength={15} min={0.0} precision={2}
                            />
                        </Form.Item>

                        <Form.Item label={null} className='md:ml-3'>
                            <div className='space-x-1'>
                                <Button type="primary" htmlType="submit" disabled={loadPage}>
                                    Buscar
                                </Button>
                                <Tooltip title="Limpiar formulario">
                                    <Button htmlType="button" onClick={onReset} className='p-1 border-0'>
                                        <RestOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </Form.Item>
                    </Form>

                    {
                        comprobante &&
                        <div className='md:ml-3 text-xs font-medium space-x-2'>
                            <Select
                                placeholder="Selecciona un adjunto"
                                allowClear
                                className='w-3/4'
                                value={foAdjunto} onChange={(v) => setFoAdjunto(v)}
                            >
                                {
                                    adjuntos.map(({ index, label }) => (
                                        <Select.Option key={index} value={index}>{label}</Select.Option>
                                    ))
                                }
                            </Select>
                            <Tooltip title="Descargar adjunto">
                                <Button htmlType="button" onClick={() => handleDownFile(true)} className='p-1 border-0' disabled={loadPage || foAdjunto === null || foAdjunto === undefined}>
                                    <CloudDownloadOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    }
                    {
                        messageApp &&
                        <div className='mt-4'>
                            <Alert className='text-xs' message={messageApp} type={messageType} showIcon closable afterClose={() => setMessageApp(null)} />
                        </div>
                    }
                    {
                        loadPage &&
                        <div className='my-5 flex justify-center'>
                            <Spin size='large' />
                        </div>
                    }
                </Card>
                <div className='w-full'>
                    <AppFooter />
                </div>
            </div>
        </div>
    )
}
