import './ConsultarCPE.css'

import { CloseCircleOutlined, SearchOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Select, Space, Spin, Switch, Table, Tooltip } from 'antd'
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosDne } from '../../../hooks/useAxiosDne'
import { segConnUrl, bllGetConUrl, stdGetTipoUrl, catGetCodUrl, usuEmsrRcptUrl, bllGetNumUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelEmsrRcpt } from '../../../components/Panel/SelEmsrRcpt'
import { AppFooter } from '../../../components/AppFooter'
import { DetalleCpe } from '../../../components/Panel/Detail/DetalleCpe'

export const ConsultarCPE = ({ page: { code: resource } }) => {
  const dvFechaIni = dayjs().startOf('month')
  const dvFechaFin = dayjs().endOf('month')

  const { axiosDneGet } = useAxiosDne()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const detCpeRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadEmsrRcpt, setLoadEmsrRcpt] = useState(true)
  const [loadTipoCpe, setLoadTipoCpe] = useState(true)
  const [loadMoneda, setLoadMoneda] = useState(true)
  const [loadEstadoCpe, setLoadEstadoCpe] = useState(false)
  const [loadEstadoDoc, setLoadEstadoDoc] = useState(false)
  const [loadBusqCpe, setLoadBusqCpe] = useState(false)
  const [allGroup, setAllGroup] = useState(false)
  const [emisores, setEmisores] = useState([])
  const [grupos, setGrupos] = useState([])
  const [estadosCpe, setEstadosCpe] = useState([])
  const [estadosDoc, setEstadosDoc] = useState([])
  const [estados, setEstados] = useState([])
  const [tiposCpe, setTiposCpe] = useState([])
  const [monedas, setMonedas] = useState([])
  const [selEmisor, setSelEmisor] = useState(null)
  const [selGrupo, setSelGrupo] = useState(null)
  const [pendiente, setPendiente] = useState(false)
  const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
  const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
  const [foEstado, setFoEstado] = useState(null)
  const [foTipoCpe, setFoTipoCpe] = useState(null)
  const [foMoneda, setFoMoneda] = useState(null)
  const [foRcptNumDoc, setFoRcptNumDoc] = useState(null)
  const [foNumCP, setFoNumCP] = useState(null)
  const [foSave, setFoSave] = useState(null)
  const [comprobantes, setComprobantes] = useState([])
  const [selComprobante, setSelComprobante] = useState(null)
  const [cpTotalDocuments, setCpTotalDocuments] = useState(0)
  const [cpPageSize, setCpPageSize] = useState(10)
  const [cpCurrentPage, setCpCurrentPage] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [, setSelectedRows] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    setLoadPage(loadTokenConn || loadEmsrRcpt || loadEstadoCpe || loadEstadoDoc || loadTipoCpe || loadMoneda)
  }, [loadTokenConn, loadEmsrRcpt, loadEstadoCpe, loadEstadoDoc, loadTipoCpe, loadMoneda])

  useEffect(() => {
    setDisablePage(loadBusqCpe)
  }, [loadBusqCpe])

  useEffect(() => {
    setEstados(pendiente ? estadosDoc : estadosCpe)
    setFoEstado(null)
  }, [pendiente, estadosDoc, estadosCpe])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosDneGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const emsrRcpt = async () => {
      const url = usuEmsrRcptUrl(resource)
      const [data, err] = await axiosDneGet(url)

      if (data)
        isMounted && setEmisores(data.emsr)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadEmsrRcpt(false)
    }

    const stdTipoCpe = async () => {
      const url = stdGetTipoUrl(configType.stdComprobante)
      const [data, err] = await axiosDneGet(url)

      if (data)
        isMounted && setEstadosCpe(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadEstadoCpe(false)
    }

    const stdTipoDoc = async () => {
      const url = stdGetTipoUrl(configType.stdDocumento)
      const [data, err] = await axiosDneGet(url)

      if (data)
        isMounted && setEstadosDoc(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadEstadoDoc(false)
    }

    const catTipoCpe = async () => {
      const url = catGetCodUrl(configType.catTipoCpe)
      const [data, err] = await axiosDneGet(url)

      if (data)
        isMounted && setTiposCpe(data.detalle.map(p => ({ ...p, label: (p.string1 || p.nombre) })))
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadTipoCpe(false)
    }

    const catMoneda = async () => {
      const url = catGetCodUrl(configType.catMoneda)
      const [data, err] = await axiosDneGet(url)

      if (data)
        isMounted && setMonedas(data.detalle.filter(dt => dt.bool1))
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadMoneda(false)
    }

    if (!loadTokenConn) {
      emsrRcpt()
      stdTipoCpe()
      stdTipoDoc()
      catTipoCpe()
      catMoneda()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  const getCpe = (cpe, index = null) => {
    var ncpe = { ...cpe, key: cpe.cpe_id, index }
    if (index === null)
      delete ncpe.index
    return ncpe
  }

  const getTitleDrawer = (cpe) => {
    if (!cpe || cpe.isDet === null || cpe.isDet === undefined)
      return ''
    else if (cpe.isDet === 1)
      return `Comprobante ( ${cpe.numCP} )`
    else
      return ''
  }

  const clearFiltroCP = (all = true) => {
    if (all) {
      setFoSave(null)
      setComprobantes([])
      setCpTotalDocuments(0)
    }
    setSelComprobante(null)
    setSelectedRows([])
    setSelectedRowKeys([])
  }

  const handlePendiente = (value) => {
    setPendiente(value)
    setFoEstado(null)
    clearFiltroCP()
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setSelComprobante(null)
  }

  const handleSelEmisor = useCallback((person) => {
    setSelEmisor(person)
    let a_grupos = []
    if (person && person.grupo.length > 0)
      a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, emsrRUC: person.ruc }))
    setGrupos(a_grupos)
    clearFiltroCP()
  }, [setSelEmisor])

  const handleSelGrupo = useCallback((person) => {
    setSelGrupo(person)
    clearFiltroCP()
  }, [setSelGrupo])

  const handleBuscarCPFecha = async () => {
    let err = null
    let data = null

    if (!selEmisor)
      err = {
        message: msgType.selectEmisor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!foFechaIni || !foFechaFin)
      err = {
        message: msgType.selectFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqCpe(true)

    const grupo = allGroup ? '' : selGrupo.ruc
    const fechaIni = foFechaIni.format().split('T')[0] + 'T00:00:00'
    const fechaFin = foFechaFin.format().split('T')[0] + 'T00:00:00'
    const estado = foEstado || ''
    const tipoCpe = foTipoCpe || ''
    const moneda = foMoneda || ''
    const rcptNumDoc = foRcptNumDoc || ''

    const url = bllGetConUrl(selEmisor.ruc, grupo, fechaIni, fechaFin, pendiente, estado, tipoCpe, moneda, rcptNumDoc, resource, 1, cpPageSize)
    const [data_api, err_api] = await axiosDneGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: cpes } = data
      setComprobantes(cpes.map((cpe, index) => getCpe(cpe, index)))
      setCpTotalDocuments(totalDocuments)
      setCpCurrentPage(1)
      clearFiltroCP(false)
      setFoSave({
        svSelEmisor: selEmisor,
        svGrupo: grupo,
        svFechaIni: fechaIni,
        svFechaFin: fechaFin,
        svPendiente: pendiente,
        svEstado: estado,
        svTipoCpe: tipoCpe,
        svMoneda: moneda,
        svRcptNumDoc: rcptNumDoc,
      })
    }
    if (err && notifyRedRef.current)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqCpe(false)
    setLastAccess()
  }

  const handleChangePagCP = async (page, pageSize) => {
    if (!foSave)
      return

    const { svSelEmisor, svGrupo, svFechaIni, svFechaFin, svPendiente, svEstado, svTipoCpe, svMoneda, svRcptNumDoc } = foSave

    let err = null
    let data = null

    setLoadBusqCpe(true)

    const url = bllGetConUrl(svSelEmisor.ruc, svGrupo, svFechaIni, svFechaFin, svPendiente, svEstado, svTipoCpe, svMoneda, svRcptNumDoc, resource, page, pageSize)
    const [data_api, err_api] = await axiosDneGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: cpes } = data
      setComprobantes(cpes.map((cpe, index) => getCpe(cpe, index)))
      setCpTotalDocuments(totalDocuments)
      setCpCurrentPage(page)
      clearFiltroCP(false)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqCpe(false)
    setLastAccess()
  }

  const handleBuscarCPNum = async () => {
    let err = null
    let data = null

    if (!selEmisor)
      err = {
        message: msgType.selectEmisor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    if (!foNumCP || foNumCP.trim().length === 0)
      err = {
        message: msgType.inputNumCP,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqCpe(true)

    const url = bllGetNumUrl(selEmisor.ruc, foNumCP, pendiente)
    const [data_api, err_api] = await axiosDneGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { success, data: cpe } = data
      if (success && (allGroup || selGrupo.ruc === cpe.grpCode)) {
        setComprobantes([getCpe(cpe, 0)])
        setCpTotalDocuments(1)
      }
      else {
        setComprobantes([])
        setCpTotalDocuments(0)
      }
      clearFiltroCP(false)
      setFoSave(null)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqCpe(false)
    setLastAccess()
  }

  const handleDetalleCpe = (record, isDet) => {
    const cpe = { ...record, isDet }
    setSelComprobante(cpe)
    setOpenDrawer(true)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(newSelectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: false,
    }),
    type: 'radio',
  }

  const cpColumns = [
    {
      title: 'RUC',
      dataIndex: 'receptor.numeroDoc',
      width: 110,
      render: (_, record) => <span>{record.receptor?.numeroDoc || ''}</span>
    },
    {
      title: 'Receptor',
      dataIndex: 'receptor.nomRazSoc',
      width: 280,
      render: (_, record) => <span>{record.receptor?.nomRazSoc || ''}</span>
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      width: 150,
      render: (_, record) => <span>{tiposCpe.find(p => p.codigo === record.tipoComprobanteID)?.label ?? record.tipoComprobante}</span>
    },
    {
      title: 'Documento',
      dataIndex: 'numCP',
      width: 120,
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            {
              allGroup &&
              <span>{record.grpCode}</span>
            }
            <button
              className='text-blue-600 w-full text-left'
              onClick={() => handleDetalleCpe(record, 1)}
            >
              <span>{`${record.serie}-${record.numero}`}</span>
            </button>
          </div>
        </div>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaEmision',
      width: 90,
      render: (_, record) => <span>{formatDate(record.fechaEmision)}</span>
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 50,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      })
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 95,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.total)}</span>
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      width: 105,
    },
  ]

  return (
    <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
      <Drawer
        title={getTitleDrawer(selComprobante)}
        placement="right"
        onClose={handleCloseDrawer}
        open={openDrawer}
        getContainer={false}
        rootStyle={{ position: 'absolute' }}
        styles={{
          body: { padding: 20, },
          header: { padding: 15, }
        }}
        destroyOnClose={true}
        maskClosable={false}
        zIndex={5}
        width={650}
        closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
        extra={
          <Space>
            <></>
          </Space>
        }
      >
        {
          selComprobante ?
            <>
              {
                selComprobante.isDet === 1 ?
                  <DetalleCpe ref={detCpeRef} selEmisor={selEmisor} selComprobante={selComprobante} /> :
                  <></>
              }
            </> :
            <></>
        }
      </Drawer >
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin size='large' />
          </div> :
          <div className='flex-grow'>
            <div className='bg-gray-200 flex-row md:flex justify-start items-center md:space-x-4 px-3 py-3 ant-common-dione'>
              <SelEmsrRcpt comboLabel="EMISOR" people={emisores} setPerson={handleSelEmisor} flex_basis='basis-96' />
              {
                !allGroup &&
                <SelEmsrRcpt comboLabel={selEmisor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
              }
              <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
            </div>

            <div className='p-3 flex flex-col ant-common-dione'>

              <p className='text-xs text-gray-700 mb-2'>
                Busca un comprobante de pago usando los filtros de fecha de emisión, estado y tipo de comprobante.
              </p>

              <div className='flex-row md:flex md:space-x-6'>
                <div className='flex items-center space-x-2'>
                  <DatePicker placeholder='Fecha inicio' defaultValue={dvFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                  <DatePicker placeholder='Fecha fin' defaultValue={dvFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                  <Switch checkedChildren="PEND" unCheckedChildren="PEND" checked={pendiente} onChange={handlePendiente} />
                  <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                    <Select placeholder='Estado' value={foEstado} allowClear style={{ width: 135 }} onChange={(v) => setFoEstado(v || null)}>
                      {
                        estados.map(({ estadoID, nombre }) => (
                          <Select.Option key={estadoID} value={nombre}>{nombre}</Select.Option>
                        ))
                      }
                    </Select>
                    <Select placeholder='Tipo' allowClear style={{ width: 200 }} onChange={(v) => setFoTipoCpe(v || null)}>
                      {
                        tiposCpe.map(({ codigo, label }) => (
                          <Select.Option key={codigo} value={codigo}>{label}</Select.Option>
                        ))
                      }
                    </Select>
                    <Select placeholder='-$-' allowClear style={{ width: 75 }} onChange={(v) => setFoMoneda(v || null)}>
                      {
                        monedas.map(({ codigo }) => (
                          <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                        ))
                      }
                    </Select>
                    <Input placeholder="RUC Receptor" style={{ width: 130 }} onChange={(e) => setFoRcptNumDoc(e.target.value)} />
                  </div>
                  {
                    !disablePage ?
                      <Tooltip title="Buscar comprobante">
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarCPFecha} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>
                <div className='flex items-center space-x-2'>
                  <Input placeholder="01-F001-100" style={{ width: 145 }} value={foNumCP} onChange={(e) => setFoNumCP(e.target.value?.toUpperCase())} />
                  {
                    !disablePage ?
                      <Tooltip title="Buscar comprobante">
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarCPNum} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>

              </div>

              <div className='lg:max-w-[71.2rem]'>
                {
                  loadBusqCpe ?
                    <div className='my-5 flex justify-center'>
                      <Spin size='large' />
                    </div> :
                    <>
                      <div className='mt-3 ant-table-dione ant-table-rowsel-dione ant-table-fnconcpe'>
                        <Table
                          rowSelection={rowSelection}
                          columns={cpColumns}
                          dataSource={comprobantes}
                          pagination={false}
                          scroll={{ y: 400 }}
                          bordered
                          size='small'
                        />
                      </div>
                      <div className='mt-2 flex justify-end ant-pagination-dione'>
                        <Pagination
                          defaultCurrent={cpCurrentPage}
                          total={cpTotalDocuments}
                          showSizeChanger
                          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} comprobantes`}
                          defaultPageSize={cpPageSize}
                          onChange={handleChangePagCP}
                          onShowSizeChange={(_, size) => setCpPageSize(size)}
                          disabled={disablePage} />
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
    </div>
  )
}